import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, number, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { formatMoneyWithoutDecimal } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { withViewport } from '../../util/contextHelpers';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  IconArrowHead,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { timestampToDate } from '../../util/dates';
import moment from 'moment';

import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
  promoteListing,
  demoteListing,
  sendTransactionOffer,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import ActionBarMaybe from './ActionBarMaybe';
import PromoteBarMaybe from './PromoteBarMaybe';

import css from './ListingPage.module.css';
import { addToWishList } from '../WishListPage/WishListPage.duck';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MINIMUM_STOCK_QUANTITY = 1;
const ADMIN_EMAILS = process.env.REACT_APP_ADMIN_EMAILS;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoneyWithoutDecimal(intl, price);

    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      isNegotiationModalOpened: false,
      sendOfferInProgress: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      prevPath: {
        page: 'SearchPage',
        search: '',
        params: '',
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSendOffer = this.onSendOffer.bind(this);
    this.toggleNegotiationModal = this.toggleNegotiationModal.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const prevPath = location && location.state && location.state.prevPath;
    if (prevPath) {
      this.setState({
        prevPath,
      });
    }
  }

  toggleNegotiationModal(bool) {
    this.setState({
      isNegotiationModalOpened: bool,
    });
  }
  onSendOfferInProgress(bool) {
    this.setState({
      sendOfferInProgress: bool,
    });
  }

  handleSubmit() {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    // Define booking start and end time
    const bookingStart = timestampToDate(
      moment(new Date())
        .add(2, 'hours')
        .valueOf()
    );
    const bookingEnd = timestampToDate(
      moment(new Date())
        .add(1, 'days')
        .valueOf()
    );

    const initialValues = {
      listing,
      bookingData: {
        quantity: 1,
        processAlias: config.bookingProcessAlias,
      },
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, shouldTransactionTransition } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values || {};

    onSendEnquiry(listingId, message.trim(), shouldTransactionTransition)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  onSendOffer(values) {
    const { history, params, onSendTransactionOffer, currentUser, shouldTransactionTransition } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message, isOffer = false, offerPrice = null } = values || {};
    console.log(shouldTransactionTransition)
    const offerParams = isOffer
      ? {
          isOffer,
          offerPrice,
          authorId: currentUser?.id?.uuid,
          createdAt: moment().unix(),
          type: 'initial',
        }
      : null;

    onSendTransactionOffer(listingId, message, offerParams, shouldTransactionTransition)
      .then(txId => {
        this.setState({ isNegotiationModalOpened: false, sendOfferInProgress: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      viewport,
      onAddToWishList,
      addToWishListInProgress,
      addToWishListError,
      currentWishListListingId,
      authorReviews,
      onPromoteListing,
      onDemoteListing,
      promoteListingInProgress,
      demoteListingInProgress,
      promoteListingError,
      demoteListingError,
      history,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;

    const search = parse(location?.search);
    const isContactOpen = search?.isContactOpen;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const stockQuantity = currentListing?.currentStock?.attributes?.quantity || 0;
    const hasStock = stockQuantity >= MINIMUM_STOCK_QUANTITY;

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
      metadata,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer currentPage="ListingPage" topbarMenuClass={css.topbarMenu} />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const isMakeOfferEnabled =
      currentAuthor?.attributes?.profile?.publicData?.recieveOffers !== 'off';

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const isFixedCommission = publicData?.fixedCommission;
    let finalPrice = isFixedCommission
      ? new Money(price?.amount + 300, price?.currency)
      : new Money(price?.amount + (price?.amount * 15) / 100, price?.currency);

    if (publicData?.category.includes('watches')) {
      finalPrice = isFixedCommission
        ? new Money(price?.amount + 300, price?.currency)
        : new Money(price?.amount + (price?.amount * 6) / 100, price?.currency);
    }

    const { formattedPrice, priceTitle } = priceData(finalPrice, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const triggerOffer = location?.triggerOffer;

    const sectionHost = className => {
      return (
        <SectionHostMaybe
          className={className}
          title={title}
          listing={currentListing}
          authorDisplayName={authorDisplayName}
          onContactUser={this.onContactUser}
          isEnquiryModalOpen={isContactOpen || (isAuthenticated && this.state.enquiryModalOpen)}
          onCloseEnquiryModal={() => {
            this.setState({ enquiryModalOpen: false });
            history.push(
              createResourceLocatorString('ListingPage', routeConfiguration(), { ...params }, {})
            );
          }}
          sendEnquiryError={sendEnquiryError}
          sendEnquiryInProgress={sendEnquiryInProgress}
          onSubmitEnquiry={this.onSubmitEnquiry}
          currentUser={currentUser}
          onManageDisableScrolling={onManageDisableScrolling}
          reviews={authorReviews}
          isContactOpen={isContactOpen}
          intl={intl}
        />
      );
    };

    // Action bar is wrapped with a div that prevents the click events
    // to the parent that would otherwise open the image carousel
    const actionBar =
      isOwnListing && currentListing.id ? (
        <div className={css.actionBarContainer} onClick={e => e.stopPropagation()}>
          <ActionBarMaybe
            listing={currentListing}
            isOwnListing={isOwnListing}
            editParams={{
              id: listingId.uuid,
              slug: listingSlug,
              type: listingType,
              tab: listingTab,
            }}
          />
        </div>
      ) : null;

    // We need to ensure that admin email from .env
    // is the same as the current logged in user email
    const currentUserEmail = currentUser?.attributes?.email;
    const isAdmin = currentUser && ADMIN_EMAILS?.split(',').includes(currentUserEmail);

    // Check if the listing has already been declared and
    // get the last promoted ID as we need it for demoting
    const isListingPromoted = metadata?.promotion?.promoted;
    const lastPromotedId = metadata?.promotion?.promotedId;

    // Promote listing params
    const promoteListingParams = {
      listingId: listingId.uuid,
      promotedId: publicData?.promoted,
    };
    // Demote listing params
    const demoteListingParams = {
      listingId: listingId.uuid,
      promotedId: lastPromotedId,
    };

    // Promote bar is wrapped with a div that prevents the click events
    // to the parent that would otherwise open the image carousel
    const promoteBar = isAdmin ? (
      <div className={css.promoteBarContainer} onClick={e => e.stopPropagation()}>
        <PromoteBarMaybe
          intl={intl}
          listing={currentListing}
          onPromoteListing={() => onPromoteListing(promoteListingParams)}
          onDemoteListing={() => onDemoteListing(demoteListingParams)}
          promoteListingInProgress={promoteListingInProgress}
          demoteListingInProgress={demoteListingInProgress}
          promoteListingError={promoteListingError}
          demoteListingError={demoteListingError}
          isListingPromoted={isListingPromoted}
        />
      </div>
    ) : null;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar className={css.topbar}>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentContainer}>
              <div className={css.mainContent}>
                {promoteBar}
                {actionBar}
                <div className={css.returnLinkContainer}>
                  <NamedLink
                    className={css.returnLink}
                    name={this.state.prevPath?.page}
                    to={{ search: this.state.prevPath?.search }}
                    params={{ ...this.state.prevPath?.params }}
                  >
                    <IconArrowHead className={css.returnLinkIcon} direction="left" />
                    <FormattedMessage id="ListingPage.return" />
                  </NamedLink>
                  {currentListing?.attributes?.createdAt ? (
                    <span className={css.published}>
                      <FormattedMessage
                        id="ListingPage.published"
                        values={{
                          date: moment(currentListing.attributes.createdAt).format('MMM, DD YYYY'),
                        }}
                      />
                    </span>
                  ) : null}
                </div>
                <SectionImages
                  title={title}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingType,
                    tab: listingTab,
                  }}
                  imageCarouselOpen={this.state.imageCarouselOpen}
                  onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                  handleViewPhotosClick={handleViewPhotosClick}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isMobileLayout={isMobileLayout}
                />
                <div className={css.mobileHeadingTitle}>{bookingTitle}</div>
                <SectionDescriptionMaybe description={description} />
                <SectionFeaturesMaybe publicData={publicData} />
                {/* <SectionMapMaybe
                  geolocation={geolocation}
                  publicData={publicData}
                  listingId={currentListing.id}
                /> */}
                {sectionHost(css.mobileHost)}
                {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
              </div>
              <BookingPanel
                className={css.bookingPanel}
                listing={currentListing}
                isOwnListing={isOwnListing}
                unitType={unitType}
                onSubmitOffer={offerValues => {
                  const offerPrice = offerValues?.negotiatedTotal?.amount / 100;
                  this.onSendOfferInProgress(true);
                  this.onSendOffer({
                    message: `I would like to make an offer for €${offerPrice}`,
                    isOffer: true,
                    offerPrice,
                  });
                }}
                onSubmit={handleBookingSubmit}
                title={bookingTitle}
                subTitle={bookingSubTitle}
                authorDisplayName={authorDisplayName}
                onManageDisableScrolling={onManageDisableScrolling}
                timeSlots={timeSlots}
                fetchTimeSlotsError={fetchTimeSlotsError}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                priceTitle={priceTitle}
                formattedPrice={formattedPrice}
                sectionHost={sectionHost()}
                isMakeOfferEnabled={isMakeOfferEnabled}
                onAddToWishList={onAddToWishList}
                currentWishListListingId={currentWishListListingId}
                addToWishListInProgress={addToWishListInProgress}
                addToWishListError={addToWishListError}
                triggerOffer={triggerOffer}
                hasStock={hasStock}
                onContactUser={this.onContactUser}
                currentUser={currentUser}
                sendOfferInProgress={this.state.sendOfferInProgress}
                history={history}
                callSetInitialValues={callSetInitialValues}
                onInitializeCardPaymentData={onInitializeCardPaymentData}
                toggleNegotiationModal={this.toggleNegotiationModal}
                isNegotiationModalOpened={this.state.isNegotiationModalOpened}
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    authorReviews,
    promoteListingInProgress,
    demoteListingInProgress,
    promoteListingError,
    demoteListingError,
  } = state.ListingPage;
  const { shouldTransactionTransition } = state.CheckoutPage;

  const {
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
  } = state.WishListPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    addToWishListInProgress,
    addToWishListError,
    currentWishListListingId,
    authorReviews,
    promoteListingInProgress,
    demoteListingInProgress,
    promoteListingError,
    demoteListingError,
    shouldTransactionTransition,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message, shouldTransactionTransition) =>
    dispatch(sendEnquiry(listingId, message, shouldTransactionTransition)),
  onSendTransactionOffer: (listingId, message, offerParams, shouldTransactionTransition) =>
    dispatch(sendTransactionOffer(listingId, message, offerParams, shouldTransactionTransition)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onAddToWishList: listingId => dispatch(addToWishList(listingId)),
  onPromoteListing: params => dispatch(promoteListing(params)),
  onDemoteListing: params => dispatch(demoteListing(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(ListingPageComponent);

export default ListingPage;
