import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const Feature = (title, value) => {
  const featureTitle = <span className={css.featureTitle}>{title}</span>;
  return value ? (
    <p className={css.feature}>
      <FormattedMessage id="SectionFeaturesMaybe.feature" values={{ featureTitle, value }} />
    </p>
  ) : null;
};

export default Feature;
