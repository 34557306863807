import React, { useState } from 'react';
import { ResponsiveImage, Modal, ImageCarousel, Carousel } from '../../components';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    isMobileLayout,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages && listing.images[0];

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  return hasImages ? (
    <div className={css.sectionImages}>
      {isMobileLayout ? (
        <div className={css.mobileThreeToTwoWrapper}>
          <div
            className={css.mobileAspectWrapper}
            onClick={e => {
              setSelectedImageIndex(0);
              handleViewPhotosClick(e);
            }}
          >
            <ResponsiveImage
              rootClassName={css.mobileRootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
        </div>
      ) : (
        <Carousel
          cards={1}
          show={hasImages}
          gutter={0}
          chevronClassName={css.chevron}
          chevronIconClassName={css.chevronIcon}
          customChevron
          fullHeightChevron
        >
          {listing?.images.map((image, index) => {
            return (
              <div className={css.threeToTwoWrapper} key={index}>
                <div
                  className={css.aspectWrapper}
                  onClick={e => {
                    setSelectedImageIndex(index);
                    handleViewPhotosClick(e);
                  }}
                >
                  <ResponsiveImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={image}
                    variants={['portrait-crop']}
                  />
                </div>
              </div>
            );
          })}
        </Carousel>
      )}
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} selectedImageFromProps={selectedImageIndex} />
      </Modal>
    </div>
  ) : null;
};

export default SectionImages;
