import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { UserCard, Modal } from '../../components';
import { EnquiryForm } from '../../forms';
import { averageNumberOfReviews } from '../../util/data';

import css from './ListingPage.module.css';

const SectionHostMaybe = props => {
  const {
    className,
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    onManageDisableScrolling,
    reviews,
    intl,
    isContactOpen = false,
  } = props;

  if (!listing.author) {
    return null;
  }

  const averageReview = averageNumberOfReviews(reviews);
  const classes = classNames(className, css.sectionHost);

  const contactMessage = intl.formatMessage(
    { id: 'ListingPage.contactMessage' },
    { title, authorDisplayName }
  );

  return (
    <div id="host" className={classes}>
      <h2 className={css.sectionHostTitle}>
        <FormattedMessage id="ListingPage.yourHostHeading" />
      </h2>
      <UserCard
        user={listing.author}
        currentUser={currentUser}
        onContactUser={onContactUser}
        averageReview={averageReview}
        numberOfReviews={reviews?.length}
      />
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          initialValues={
            isContactOpen
              ? {
                  message: contactMessage,
                }
              : {}
          }
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionHostMaybe;
