import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { getPlacePredictions } from '../../util/googleMaps';
import Geocoder from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import { Map } from '../../components';
import config from '../../config';

import css from './ListingPage.module.css';

const getAddressCityCenter = async address => {
  const service = new google.maps.Geocoder();
  const GeocoderAPI = new Geocoder();

  const cityResults = await service.geocode({ address });
  const city = cityResults?.results[0]?.address_components?.find(component =>
    component?.types?.includes('locality')
  );

  if (city) {
    const cityName = city ? city.long_name || city.short_name : null;

    const placePredictions = await getPlacePredictions(cityName);
    const predictions = placePredictions?.predictions;
    const firstPrediction = predictions?.[0];

    const placeOrigin = firstPrediction ? await GeocoderAPI.getPlaceDetails(firstPrediction) : null;
    return placeOrigin?.origin || null;
  } else {
    return null;
  }
};

const SectionMapMaybe = props => {
  const { className, rootClassName, geolocation, publicData, listingId } = props;

  if (!geolocation) {
    return null;
  }

  const [isStatic, setIsStatic] = useState(true);
  const [mapCenter, setMapCenter] = useState(geolocation);

  const address = publicData && publicData.location ? publicData.location.address : '';
  const classes = classNames(rootClassName || css.sectionMap, className);
  const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

  useEffect(() => {
    if (address && address !== '') {
      getAddressCityCenter(address).then(cityMapCenter => {
        if (cityMapCenter) {
          setMapCenter(cityMapCenter);
        }
      });
    } else {
      setMapCenter(geolocation);
    }
  }, [address]);

  const mapProps = config.maps.fuzzy.enabled
    ? { obfuscatedCenter: obfuscatedCoordinates(mapCenter, cacheKey) }
    : { address, center: mapCenter };
  const map = <Map {...mapProps} useStaticMap={isStatic} />;

  return (
    <div className={classes}>
      <h2 className={css.locationTitle}>
        <FormattedMessage id="ListingPage.locationTitle" />
      </h2>
      {isStatic ? (
        <button
          className={css.map}
          onClick={() => {
            setIsStatic(false);
          }}
        >
          {map}
        </button>
      ) : (
        <div className={css.map}>{map}</div>
      )}
    </div>
  );
};

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
