import React from 'react';
import { array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import includes from 'lodash/includes';
import config from '../../config';

import Feature from './Feature';
import css from './ListingPage.module.css';

const checkSelected = (options, selectedOption) => {
  return options?.find(o => o.key === selectedOption)?.label;
};

const checkMultipleSelected = (options, selectedOptions) => {
  const findSelected = options?.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: includes(selectedOptions, option.key),
  }));

  return findSelected
    ?.filter(i => i.isSelected)
    .map(i => i.label)
    .join(', ');
};

const SectionFeaturesMaybe = props => {
  const {
    publicData,
    conditionConfig,
    colourConfig,
    garmentsPatternsConfig,
    germentSizeConfig,
    accessoriesPatternsConfig,
    shirtsPatternsConfig,
  } = props;

  if (!publicData) {
    return null;
  }

  // Public data fields
  const {
    condition,
    colour,
    jacketsSuitsCoatsAndKnitwearUkSizeChest,
    garmentsPatterns,
    garmentLength,
    accessoriesPatterns,
    footwearSizeUk,
    trousersSizeWaist,
    shirtsSizeCollar,
    shirtsPatterns,
  } = publicData || {};

  // Find the proper label from publicData key
  const getCondition = Array.isArray(condition)
    ? checkMultipleSelected(conditionConfig, condition)
    : checkSelected(conditionConfig, condition);
  const getColours = checkMultipleSelected(colourConfig, colour);
  const getPatterns = checkMultipleSelected(garmentsPatternsConfig, garmentsPatterns);
  const getGermentSize = Array.isArray(garmentLength)
    ? checkMultipleSelected(germentSizeConfig, garmentLength)
    : checkSelected(germentSizeConfig, garmentLength);
  const getAccessoriesPatterns = Array.isArray(accessoriesPatterns)
    ? checkMultipleSelected(accessoriesPatternsConfig, accessoriesPatterns)
    : checkSelected(accessoriesPatternsConfig, accessoriesPatterns);
  const getShirtsPatterns = Array.isArray(shirtsPatterns)
    ? checkMultipleSelected(shirtsPatternsConfig, shirtsPatterns)
    : checkSelected(shirtsPatternsConfig, shirtsPatterns);

  const hideFeatures = getCondition || getColours;

  return hideFeatures ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      {Feature(
        'UK size',
        jacketsSuitsCoatsAndKnitwearUkSizeChest && jacketsSuitsCoatsAndKnitwearUkSizeChest / 10
      )}
      {Feature('Trousers size - Waist', trousersSizeWaist && trousersSizeWaist / 10)}
      {Feature('Footwear size - UK', footwearSizeUk && footwearSizeUk / 10)}
      {Feature('Shirts size - Collar', shirtsSizeCollar && shirtsSizeCollar / 10)}
      {Feature('Condition', getCondition)}
      {Feature('Patterns', getPatterns)}
      {Feature('Pattern', getAccessoriesPatterns)}
      {Feature('Pattern', getShirtsPatterns)}
      {Feature('Length', getGermentSize)}
      {Feature('Colour', getColours)}
    </div>
  ) : null;
};

SectionFeaturesMaybe.defaultProps = {
  conditionConfig: config.custom.condition,
  colourConfig: config.custom.colour,
  garmentsPatternsConfig: config.custom.garmentsPatterns,
  germentSizeConfig: config.custom.garmentSize,
  accessoriesPatternsConfig: config.custom.accessoriesPatterns,
  shirtsPatternsConfig: config.custom.shirtPatterns,
};

SectionFeaturesMaybe.propTypes = {
  conditionConfig: array,
  colourConfig: array,
  garmentsPatternsConfig: array,
  germentSizeConfig: array,
  accessoriesPatternsConfig: array,
  shirtsPatternsConfig: array,
};

export default SectionFeaturesMaybe;
