import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { InlineTextButton, IconPromote, IconDemote, IconSpinner } from '../../components';

import css from './ListingPage.module.css';

export const PromoteBarMaybe = props => {
  const {
    intl,
    listing,
    isListingPromoted,
    onPromoteListing,
    onDemoteListing,
    promoteListingInProgress,
    demoteListingInProgress,
    promoteListingError,
    demoteListingError,
  } = props;
  const state = listing.attributes.state;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isError = promoteListingError || demoteListingError;

  const promotionDisabled = isPendingApproval || isClosed || isDraft || isError;

  if (promotionDisabled) {
    let listingStateTextTransationId = 'ListingPage.cantPromoteListing';

    if (isPendingApproval) {
      listingStateTextTransationId = 'ListingPage.promoteListingPendingApproval';
    } else if (isClosed) {
      listingStateTextTransationId = 'ListingPage.promoteClosedListing';
    } else if (isDraft) {
      listingStateTextTransationId = 'ListingPage.promoteListingDraft';
    } else if (isError) {
      listingStateTextTransationId = 'ListingPage.promoteListingError';
    }

    return (
      <div className={css.promoteBar}>
        <p className={css.closedListingText}>
          <FormattedMessage id={listingStateTextTransationId} />
        </p>
      </div>
    );
  } else {
    let promoteOrDemoteTextTranslationId = 'ListingPage.promoteListing';
    let promoteOrDemoteButtonTranslationId = 'ListingPage.promoteListingButton';
    let promoteOrDemoteIcon = <IconPromote className={css.promoteIcon} />;
    let promoteOrDemoteTitle = intl.formatMessage({
      id: 'ListingPage.promoteListingTitle',
    });

    if (isListingPromoted) {
      promoteOrDemoteTextTranslationId = 'ListingPage.demoteListing';
      promoteOrDemoteButtonTranslationId = 'ListingPage.demoteListingButton';
      promoteOrDemoteIcon = <IconDemote className={css.demoteIcon} />;
      promoteOrDemoteTitle = intl.formatMessage({ id: 'ListingPage.demoteListingTitle' });
    }

    const actionInProgress = promoteListingInProgress || demoteListingInProgress;

    return (
      <div className={css.promoteBar}>
        <p className={css.promoteBarText}>
          <FormattedMessage id={promoteOrDemoteTextTranslationId} />
        </p>

        <InlineTextButton
          className={css.editListingLink}
          title={promoteOrDemoteTitle}
          onClick={isListingPromoted ? onDemoteListing : onPromoteListing}
          disabled={actionInProgress}
        >
          {actionInProgress ? <IconSpinner className={css.spinnerIcon} /> : promoteOrDemoteIcon}
          <FormattedMessage id={promoteOrDemoteButtonTranslationId} />
        </InlineTextButton>
      </div>
    );
  }
};

PromoteBarMaybe.propTypes = {
  listing: propTypes.listing.isRequired,
};

PromoteBarMaybe.displayName = 'PromoteBarMaybe';

export default PromoteBarMaybe;
